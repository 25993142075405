<template>
  <DoctorsCell
    :header="header"
    :item="item"
    :value="doctors"
    @clicked="handleClick"
  >
  </DoctorsCell>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined,
    },
    item: {
      default: undefined,
    },
  },
  computed: {
    doctors() {
      const doctorList = [];
      const regex = /^{"id":\d+,"name":"([\s\S]+)"}$/;
      (this.item[this.header.value] || []).forEach((doctor) => {
        const name = doctor.match(regex);
        let oldName = "";
        if (name.length > 1) {
          oldName = name[1];
        } else {
          return;
        }
        const newName = oldName.replace(/"/g, '\\"');
        doctor = doctor.replace(oldName, newName);
        doctorList.push(JSON.parse(doctor));
      });
      return doctorList;
    },
  },
  components: {
    DoctorsCell: () => import("@/components/tables/DoctorsCell"),
  },
  methods: {
    handleClick(header, item, doctor) {
      this.$emit("clicked", header, item, doctor);
    },
  },
};
</script>
